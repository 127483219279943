<template>
    <v-container fluid class="container--fluid fill-height text-left pa-0 backcolor" style="width: 100%">
        <v-row>
            <v-img src="@/assets/images/header-bg.jpg"
                   class="align-end"
                   transition="slide-x-transition"
                   width="100%" height="300">
                <v-row align="end" justify="center">
                    <v-col cols="12">
                        <div class="fill-height container--fluid px-5 px-md-16 pb-10">
                            <span class="font-weight-thin text-h5 mx-2 text-md-h2 white--text">
                               Categorías
                            </span>
                            <v-divider dark class="mt-2"/>
                        </div>
                    </v-col>
                </v-row>
            </v-img>
        </v-row>
        <v-row>
            <v-container fluid class="container--fluid fill-height text-left pa-auto pa-md-16 pt-5 backcolor">
                <v-row align="center" justify="center">
                    <v-col cols="12">
                        <div class="fill-height container--fluid text-h4 text-md-h3 blue--text text--darken-2">
                            {{ category.name }}
                        </div>
                        <v-divider class="mt-2"/>
                    </v-col>
                    <v-col cols="12">
                        <div class="fill-height container--fluid">
                            {{ category.description }}
                        </div>
                    </v-col>
                </v-row>
                <v-row align="start" justify="start" class="text-left mt-10">
                    <v-col v-for="item in category.projects"
                           :key="item.id"
                           cols="12" md="4"
                           class="mx-0 px-auto">
                        <!--pl-5-->
                        <v-hover v-slot:default="{ hover }">
                            <v-card :elevation="hover ? 10 : 4"
                                    class="card mx-2 mx-md-0"
                                    @click="showProject(item.id)">
                                <v-img :src="item.pictures.length>0 ? item.pictures[0].url : require('@/assets/images/no-image.jpg')"
                                       height="350"
                                       width="auto"
                                       gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                       aspect-ratio="16/9"
                                       class="align-end mt-0 ml-0">
                                    <v-expand-transition>
                                        <div
                                            v-if="hover"
                                            class="d-flex transition-fast-in-fast-out blue darken-2 v-card--reveal white--text"
                                            style="height: 100%;">
                                            <v-container fluid class="fill-height container--fluid ma-auto mb-2">
                                                <v-row align="end" justify="start">
                                                    <v-col cols="12">
                                                        <table cellpadding="0" cellspacing="0" width="100%">
                                                            <tr>
                                                                <td colspan="2">
                                                                    <span class="white--text text-subtitle-1">
                                                                        {{ item.name }}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="vertical-align:top" width="auto">
                                                                    <v-icon color="yellow" small>mdi-map-marker</v-icon>
                                                                </td>
                                                                <td>
                                                                    <span class="white--text text-caption">
                                                                        {{ item.location }}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </div>
                                    </v-expand-transition>
                                </v-img>
                            </v-card>
                        </v-hover>
                    </v-col>
                </v-row>
            </v-container>
        </v-row>
    </v-container>
</template>

<script>
    import categoriesService from "@/providers/CategoriesService";

    export default {
        name: "CategoryProfileComponent",
        data: () => ({
            category: null,
            projects: []
        }),
        methods: {
            getCategory(category_id) {
                let record = null;
                categoriesService.getRecord(category_id).then(fetch_data => {
                    record = fetch_data.value;
                    this.category = record;
                    // this.toTop();
                });
            },
            showProject(project_id) {
                this.$router.push({
                    name: "ProjectProfile",
                    params: {
                        id: project_id,
                        view: 'project',
                        user: 'guest'
                    }
                });
            }
        },
        mounted() {
            this.projects = [];
            this.getCategory(this.$route.params.id);
        }
    }

</script>

<style scoped>

</style>