<template>
    <category-profile-component/>
</template>

<script>
    import CategoryProfileComponent from "@/components/projects/CategoryProfileComponent";

    export default {
        name: "CategoryProfile",
        title: "Perfil de Categoría | Private",
        components: {CategoryProfileComponent}
    }
</script>

<style scoped>

</style>